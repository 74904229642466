export const isUrl = (urlString: string): boolean => {
    try {
        const url = new URL(urlString);

        const isValidProtocol =
            url.protocol === 'http:' || url.protocol === 'https:' || url.protocol === 'ftp:';

        const domainRegex = /^[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
        const isValidDomain = domainRegex.test(url.hostname);

        return isValidProtocol && isValidDomain;
    } catch (error) {
        return false;
    }
};
const PARTOO_APP_PROD_HOSTNAME = 'app.partoo.co';
const PARTOO_ADMIN_PROD_HOSTNAME = 'admin.partoo.co';
const MEDIAPOST_PROD_HOSTNAME = 'app.mavisibiliteweb.fr';
const JSSDK_PROD_HOSTNAME = 'https://jssdk-playground.partoo.co';
const SLATES_ENV_KEY = 'slates';
export const PROD_HOSTNAMES = [
    PARTOO_APP_PROD_HOSTNAME,
    PARTOO_ADMIN_PROD_HOSTNAME,
    MEDIAPOST_PROD_HOSTNAME,
    JSSDK_PROD_HOSTNAME,
];

export type PartooSubdomain = {
    reference: string | null;
    subdomain: string | null;
};

/**
 * Extracts the `reference` (a numeric identifier) and `subdomain` from the current hostname.
 * Handles specific environments related to the `partoo.co` platform.
 *
 * @returns {PartooSubdomain} An object containing:
 * - `reference`: A numeric identifier if present in the hostname, otherwise `null`.
 * - `subdomain`: The environment key (e.g., 'staging', 'slates', 'preprod', 'sandbox') or an empty string for production domains. Returns `null` for invalid domains.
 */
export const extractPartooSubdomain = (): PartooSubdomain => {
    const hostname = window.location.hostname;
    if (PROD_HOSTNAMES.includes(hostname)) {
        return { reference: null, subdomain: '' };
    }
    const domainPattern = /^(\d+)?\.?(app|admin|jssdk-playground)\.([^.]+)\.partoo\.co/i;
    const match = domainPattern.exec(hostname);
    if (!match) {
        return { reference: null, subdomain: null };
    }
    const reference = match[1] ?? null;
    const subdomain = match[3] === SLATES_ENV_KEY ? SLATES_ENV_KEY : (match[3] ?? '');
    return subdomain === SLATES_ENV_KEY ? { reference, subdomain } : { reference: null, subdomain };
};
