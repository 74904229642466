import { extractPartooSubdomain } from 'app/utils/urls';
const { reference, subdomain } = extractPartooSubdomain();
const CHECK_IS_SANDBOX = process.env['IS_SANDBOX'] === 'sandbox';
export const ENV = CHECK_IS_SANDBOX
    ? 'sandbox'
    : !subdomain || subdomain === ''
      ? 'prod'
      : subdomain;
// ASSETS paths
const STATIC_ASSETS_BASE_URL = 'https://partoo-media.s3.eu-west-1.amazonaws.com/assets';
export const STATIC_APP_ASSETS_URL = `${STATIC_ASSETS_BASE_URL}/app`;
export const STATIC_ADMIN_ASSETS_DIR = `${STATIC_ASSETS_BASE_URL}/admin`;
export const STATIC_COMMON_ASSETS_DIR = `${STATIC_ASSETS_BASE_URL}/common`;
// Default set to prod urls
const DEFAULT_APP_BASE_URL = 'https://app.partoo.co';
const DEFAULT_ADMIN_BASE_URL = 'https://admin.partoo.co';
const DEFAULT_STATIC_BASE_URL = 'https://static.partoo.co';
const DEFAULT_APP_API_BASE_URL = `${DEFAULT_APP_BASE_URL}/api/v2`;
const DEFAULT_ADMIN_API_BASE_URL = `${DEFAULT_ADMIN_BASE_URL} /api/v2`;
const DEFAULT_SENTRY_DSN =
    'https://fd92d2cce66347dcb28ef2d21f031d7c@o956265.ingest.sentry.io/5905624';
const DEFAULT_WEBSOCKET_LIVE_UPDATES_URL = 'wss://websockets.prod.partoo.co';
const DEFAULT_FEEDBACK_MANAGEMENT_BASE_URL = 'https://survey.partoo.co';
const DEFAULT_WIDGET_URL = 'https://cdn.partoo.co/widget/prod/latest.js';
export let BASE_URL = DEFAULT_APP_BASE_URL;
export let STATIC_BASE_URL = DEFAULT_STATIC_BASE_URL;
export let APP_API_BASE_URL = DEFAULT_APP_API_BASE_URL;
export let ADMIN_API_BASE_URL = DEFAULT_ADMIN_API_BASE_URL;
export let SENTRY_DSN = DEFAULT_SENTRY_DSN;
export let WEBSOCKET_LIVE_UPDATES_URL = DEFAULT_WEBSOCKET_LIVE_UPDATES_URL;
export let FEEDBACK_MANAGEMENT_BASE_URL = DEFAULT_FEEDBACK_MANAGEMENT_BASE_URL;
export let WHATSAPP_APP_ID = '539617142869973';
export let WHATSAPP_EMBEDDED_SIGNUP_CONFIG_ID = '297841676428960';
export let WIDGET_URL = DEFAULT_WIDGET_URL;
export const DEBUG = ENV !== 'prod';
export const getUrlsFromSubdomain = (subdomain: string | null) => {
    if (!subdomain) return;
    BASE_URL = `https://app.${subdomain}.partoo.co`;
    APP_API_BASE_URL = `https://app.${subdomain}.partoo.co/api/v2`;
    ADMIN_API_BASE_URL = `https://admin.${subdomain}.partoo.co/api/v2`;
    STATIC_BASE_URL = `https://static.${subdomain}.partoo.co`;
    WEBSOCKET_LIVE_UPDATES_URL = `wss://websockets.${subdomain}.partoo.co`;
    FEEDBACK_MANAGEMENT_BASE_URL = `https://survey.${subdomain}.partoo.co`;
    WIDGET_URL = `https://cdn.partoo.co/widget/${subdomain}/latest.js`;
};
switch (ENV) {
    case 'prod':
        break;
    case 'dev':
        BASE_URL = `https://app.${subdomain}.partoo.co`;
        APP_API_BASE_URL = `https://app.${subdomain}.partoo.co/api/v2`;
        ADMIN_API_BASE_URL = `https://admin.${subdomain}.partoo.co/api/v2`;
        STATIC_BASE_URL = `https://static.${subdomain}.partoo.co`;
        SENTRY_DSN = '';
        WEBSOCKET_LIVE_UPDATES_URL = 'wss://app.dev.partoo.co/websockets';
        FEEDBACK_MANAGEMENT_BASE_URL = `https://survey.${subdomain}.partoo.co`;
        // Select this URL for local testing, follow widget repo readme.
        //WIDGET_URL = 'http://127.0.0.1:8081/dist/partoo-messaging-widget.js';
        WIDGET_URL = 'https://cdn.partoo.co/widget/preprod/latest.js';
        break;
    case 'sandbox':
        BASE_URL = 'https://app.sandbox.partoo.co';
        APP_API_BASE_URL = 'https://app.sandbox.partoo.co/api/v2';
        ADMIN_API_BASE_URL = 'https://admin.sandbox.partoo.co/api/v2';
        STATIC_BASE_URL = 'https://static.sandbox.partoo.co';
        SENTRY_DSN = '';
        WEBSOCKET_LIVE_UPDATES_URL = 'wss://websockets.sandbox.partoo.co';
        FEEDBACK_MANAGEMENT_BASE_URL = 'https://survey.sandbox.partoo.co';
        WIDGET_URL = 'https://cdn.partoo.co/widget/sandbox/latest.js';
        break;
    case 'slates': {
        if (reference && subdomain) {
            BASE_URL = `https://${reference}.app.${subdomain}.partoo.co`;
            APP_API_BASE_URL = `https://${reference}.app.${subdomain}.partoo.co/api/v2`;
            ADMIN_API_BASE_URL = `https://${reference}.admin.${subdomain}.partoo.co/api/v2`;
            STATIC_BASE_URL = `https://${reference}.static.${subdomain}.partoo.co`;
            WEBSOCKET_LIVE_UPDATES_URL = `wss://${reference}.websockets.${subdomain}.partoo.co`;
            FEEDBACK_MANAGEMENT_BASE_URL = `https://${reference}.survey.${subdomain}.partoo.co`;
            WIDGET_URL = `https://cdn.partoo.co/widget/${reference}.${subdomain}/latest.js`;
        }
        break;
    }
    default:
        getUrlsFromSubdomain(subdomain);
        // whatsapp config for staging
        WHATSAPP_APP_ID = '1038640290773644';
        WHATSAPP_EMBEDDED_SIGNUP_CONFIG_ID = '1037525230684636';
        break;
}
